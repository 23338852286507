import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../auth/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faFileExport, faPenToSquare, faTrash, faFloppyDisk, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'qrcode';
import potrace from 'potrace';
import '../pages/dataList.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const DispalData = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const [editingSlug, setEditingSlug] = useState('');
  const [editingUrl, setEditingUrl] = useState('');
  const [openIndex, setOpenIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [qrData, setQRData] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const canvasRef = useRef(null);
  const svgRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'urls'));
      const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setData(dataArray);
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleEditClick = (index, item) => {
    setEditingIndex(index);
    setEditingTitle(item.title);
    setEditingSlug(item.slug);
    setEditingUrl(item.url);
  };

  const handleEditChange = (e, field) => {
    if (field === 'title') setEditingTitle(e.target.value);
    if (field === 'slug') setEditingSlug(e.target.value);
    if (field === 'url') setEditingUrl(e.target.value);
  };

  const handleEditSave = async (id) => {
    try {
      const docRef = doc(db, 'urls', id);
      await updateDoc(docRef, { title: editingTitle, slug: editingSlug, url: editingUrl });
      setData(data.map((item, index) => 
        index === editingIndex ? { ...item, title: editingTitle, slug: editingSlug, url: editingUrl } : item
      ));
      setEditingIndex(null);
      setEditingTitle('');
      setEditingSlug('');
      setEditingUrl('');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await deleteDoc(doc(db, 'urls', id));
        setData(data.filter(item => item.id !== id));
      } catch (error) {
        console.error('Error deleting document: ', error);
      }
    }
  };

  const handleGenerateQRCode = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal && selectedItem) {
      const generateQRCode = async () => {
        try {
          const QRLink = `https://sufra-qr.com/${selectedItem.slug}`;
          setQRData(QRLink);
          const qrDataURL = await QRCode.toDataURL(QRLink, { width: 800, height: 800 });
          const canvas = canvasRef.current;
          const ctx = canvas.getContext('2d');
          const img = new Image();
          img.src = qrDataURL;
          img.onload = () => {
            ctx.drawImage(img, 0, 0);
          };
        } catch (error) {
          console.error('Error generating QR code:', error);
        }
      };

      generateQRCode();
    }
  }, [showModal, selectedItem]);

  const handleExportPNG = () => {
    const canvas = canvasRef.current;
    const pngURL = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngURL;
    downloadLink.download = `qr_code_${selectedItem.slug}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleExportSVG = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    potrace.posterize(imgData, (err, svg) => {
      if (err) {
        console.error('Error converting to SVG:', err);
        return;
      }

      const svgBlob = new Blob([svg], { type: 'image/svg+xml' });
      const svgURL = URL.createObjectURL(svgBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = svgURL;
      downloadLink.download = `qr_code_${selectedItem.slug}.svg`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const filteredData = data.filter(item =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.slug.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.url.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const mainLink = "https://sufra-qr.com/";

  return (
    <>
      <h1 className='mt-3'>Alfa Co. Links & QR</h1>
      <div className='data-display'>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="position-fixed bottom-0 searchBox"
        />
        <div className='data-list p-3'>
          <ol>
            {filteredData.map((item, index) => (
              <li key={index}>
                {editingIndex === index ? (
                  <div className='rounded border border-danger p-3'>
                    <input
                      type="text"
                      value={editingTitle}
                      onChange={(e) => handleEditChange(e, 'title')}
                      placeholder="Title"
                      className='form-control mt-1'
                    />
                    <input
                      type="text"
                      value={editingSlug}
                      onChange={(e) => handleEditChange(e, 'slug')}
                      placeholder="Slug"
                      className='form-control mt-1'
                    />
                    <input
                      type="text"
                      value={editingUrl}
                      onChange={(e) => handleEditChange(e, 'url')}
                      placeholder="URL"
                      className='form-control mt-1'
                    />
                    <FontAwesomeIcon icon={faFloppyDisk} className='mt-2 fa-2x' variant='success' onClick={() => handleEditSave(item.id)}/>&nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faCircleXmark} className='mt-2 fa-2x' variant='outline-danger' onClick={() => setEditingIndex(null)}/>
                  </div>
                ) : (
                  <>
                    <div className='text-break'>
                      <strong className='text-uppercase'>{item.title}<br /></strong> 
                      Link: <a className='slugText' href={mainLink + item.slug} target='_blank' rel='noopener noreferrer'><strong>{item.slug}</strong></a>&nbsp;&nbsp;
                      URL: <FontAwesomeIcon
                        icon={faLink}
                        onClick={() => setOpenIndex(openIndex === index ? null : index)}
                        aria-controls={`collapse-text-${index}`}
                        aria-expanded={openIndex === index}
                        className='click-icon fa-light slugText'
                      />
                      <Collapse in={openIndex === index}>
                        <div id={`collapse-text-${index}`}>
                          <span className="data-color"> {item.url} </span>
                        </div>
                      </Collapse> <br/>
                      <span className='mt-1'>
                      <FontAwesomeIcon  icon={faFileExport} onClick={() => handleGenerateQRCode(item)} variant="info"/>&nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon className='fa-fw' icon={faPenToSquare} onClick={() => handleEditClick(index, item)} />&nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faTrash} variant='outline-danger' onClick={() => handleDeleteClick(item.id)} />
                  </span>
                    </div>
                
                  </>
                )}
              </li>
            ))}
          </ol>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedItem ? selectedItem.title : ''}
            <p className='small-text'> <a href={selectedItem ? selectedItem.slug : ''} target='_blank'> {mainLink}{selectedItem ? selectedItem.slug : ''}</a></p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <canvas className='qr-wrapper' ref={canvasRef} width={800} height={800} style={{ border: '.5px solid #eaeaea', display: 'block', margin: '0 auto' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleExportPNG} variant="primary">Export as PNG</Button>
          <Button onClick={handleExportSVG} variant="primary">Export as SVG</Button>
          <Button variant="danger" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DispalData;
