import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";


const firebaseConfig = {
    apiKey: "AIzaSyDIS-93oOLKk8kaVNNdqZeZjqqxsFzG_TA",
    authDomain: "shorten-url-f918e.firebaseapp.com",
    projectId: "shorten-url-f918e",
    storageBucket: "shorten-url-f918e.appspot.com",
    messagingSenderId: "724376226895",
    appId: "1:724376226895:web:d1ee33fe3fb1b855202e3a",
  };

  firebase.initializeApp(firebaseConfig);
  const auth = firebase.auth();
  const db = firebase.firestore();

  // Enable offline persistence
db.enablePersistence()
.catch((err) => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    console.log('Persistence failed');
  } else if (err.code === 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    console.log('Persistence is not available');
  }
});

export { auth, db };
