// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from "react-router-dom";
import { useAuth } from '../auth/useAuth';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    return <Component {...rest} />;
};

export default ProtectedRoute;
