// src/components/Login.js
import React, { useState } from 'react';
import { auth } from '../auth/firebase';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import '../index.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        try {
            await auth.signInWithEmailAndPassword(email, password);
            navigate('/melan');  // Redirect to a protected route
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Container>
        <Row>
        <div className='justify-content-center align-items-center login'>
            <h2 className='text-center mt-5'>Login</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                    className='form-control'
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                    className='form-control'
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button className='btn btn-success mt-3' type="submit">Login</button>
            </form>
        </div>
        </Row>
        </Container>
    );
};

export default Login;
