import React, { useEffect, useState } from 'react';
import { db } from '../auth/firebase';
import { useParams, useNavigate } from 'react-router-dom';
import SufraIcon from '../SufraIcon.png';
import firebase from 'firebase/compat/app';

function Redirect() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Optimized query to fetch the document with the specific slug
        const query = db.collection('urls').where('slug', '==', slug).limit(1);
        const snapshot = await query.get();

        if (snapshot.empty) {
          alert('No URL Found!');
          navigate('/');
          return;
        }

        const doc = snapshot.docs[0];
        const finalData = doc.data();

        // Redirect to the URL
        window.location.href = finalData.url;

        // Add a new record in the clicked-logs collection
        await db.collection('clicked-logs').add({
          slugName: slug,
          Title: finalData.title || 'No Title',
          url: finalData.url,
          timeStamp: new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" }),
          accumulatedClicks: 1
        });

        // Update or create a record in the total-clicks collection
        const totalClicksRef = db.collection('total-clicks').doc(slug);
        const totalClicksDoc = await totalClicksRef.get();

        if (totalClicksDoc.exists) {
          // If the document exists, update the accumulatedClicks count
          await totalClicksRef.update({
            accumulatedClicks: firebase.firestore.FieldValue.increment(1),
            lastClicked: new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
          });
        } else {
          // If the document does not exist, create a new one
          await totalClicksRef.set({
            slugName: slug,
            accumulatedClicks: 1,
            lastClicked: new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
          });
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Error loading data.');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug, navigate]);

  return (
    <div style={{ height: '100%', width: '100%', backgroundColor: '#007b4f'}}>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <img src={SufraIcon} alt="Loading..." />
        </div>
      ) : null}
    </div>
  );
}

export default Redirect;