
import React, { useState } from 'react';
import { parse } from 'papaparse';
import { db } from '../auth/firebase';
import { collection, addDoc } from 'firebase/firestore';

const UploadForm = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;

      // Parse the CSV file
      const result = parse(text, { header: true });
      const data = result.data;

      try {
        const collectionRef = collection(db, 'urls');

        for (const row of data) {
          const { slug, title, url } = row;
          await addDoc(collectionRef, { slug, title, url });
        }

        alert('Data uploaded successfully');
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    };

    reader.readAsText(file);
  };

  return (
    <>
    <div className='data-display p-3'>
    <h3 className='p-2'>Bulk Upload File:</h3>
    <form onSubmit={handleSubmit}>
      <input className='form-control mt-3' type="file" accept=".csv" onChange={handleFileChange} />
      <button className='btn btn-success mb-2 mt-2' type="submit">Upload</button>
    </form>
    </div>
    </>
  );
};

export default UploadForm;