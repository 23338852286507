// src/App.js
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Input from "./pages/Input"; // Adjust path relative to App.js
import Redirect from "./pages/Redirect"; // Assuming this is your custom component
import DisplayData from "./pages/dataList";
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './auth/useAuth';
import Login from "./components/Login";
import './pages/dataList.css';
import LogoutButton from "./components/LogoutButton";
import { Container } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import UploadForm from "./pages/UploadForm";
import MarketingList from "./pages/Marketing";

function App() {
    return (
        <AuthProvider >
            <Router>
                <Routes>
                <Route path="/:slug" element={<Redirect />} />
                <Route path="/" element={<home />} />
                <Route path="/data" element={
                    <>
                    <Container>
                    <MarketingList />
                    </Container>
                    <div className='search-bg'></div>
                    </>
                    } />
                    <Route path="/login" element={<Login />} />
                    <Route path="/melan" element={
                    <>
                    <Container>
                    <h2 className="mt-4">Welcome, Melan!</h2>
                    <LogoutButton />
                            <ProtectedRoute path="/input" component={Input} />
                            <ProtectedRoute path="/display-data" component={DisplayData} />
                            <ProtectedRoute path="/upload" component={UploadForm} />
                            </Container>
                            <div className='search-bg'></div>
                        </>
                    } />
                </Routes>
            </Router>
            <br/><br/><br/>
        </AuthProvider>
    );
}

export default App;
